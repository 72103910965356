import React from 'react';

interface Props {
  item: any;
  searchedProduct?: any;
  index: number;
}

export default function Result({ item, searchedProduct, index }: Props) {
  const trimmedLink = item.link?.split('?')[0];
  return (
    <a
      className={`Result flex xxs:flex-row flex-col mt-5 sm:mt-10 p-3 sm:p-5 rounded-2xl border-card-background border hover:bg-card-background-hover hover:border-primary active:bg-card-background-light ${item?.weightAttributes?.totalScore > 70 ? 'border-primary-20 bg-card-background' : 'bg-black'}`}
      href={trimmedLink}
      target=" _blank">
      <div className={"sm:w-40 sm:h-40 xs:w-36 xxs:w-28 w-full h-40"}>
        <img src={item.thumbnail} alt={"Main product img missing"}
             className="rounded-xl object-cover sm:h-40 xs:w-36 xxs:w-28 w-full h-40"/>
      </div>
      <div className="details xxs:ml-3 sm:ml-5 flex-1 flex flex-col justify-between items-start">
        <div>
          {item?.weightAttributes?.totalScore ?
            <div
              className={`px-2 py-0.5 mt-2 rounded-md text-xs w-auto inline-block font-bold text-black ${item?.weightAttributes?.totalScore > 80 ? 'bg-s-tier' : item?.weightAttributes?.totalScore > 70 ? 'bg-a-tier' : item?.weightAttributes?.totalScore > 60 ? 'bg-b-tier' : 'bg-c-tier'}`}>
              {item?.weightAttributes?.totalScore > 80 ? 'S Tier' : item?.weightAttributes?.totalScore > 70 ? 'A Tier' : item?.weightAttributes?.totalScore > 60 ? 'B Tier' : 'C Tier'}
            </div>
            : null
          }

          <div className={"text-sm sm:text-base font-bold mt-2 sm:mt-3 text-ellipsis line-clamp-2"}>
            {index}. {item.title}
          </div>
          <div className="flex flex-row items-center text-xs sm:text-sm mt-2">
            <i className="fal fa-link text-primary"></i>
            <div className="ml-2 capitalize">
              {item.source.toLowerCase()}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center mt-3">
          <div
            className={`price-button flex ${item.price.extracted_value < searchedProduct?.price ? 'cheaper' : 'same-price'}`}>
            {
              item.price.extracted_value < searchedProduct?.price ?
                <div
                  className="left text-xs font-medium text-black bg-text rounded-l-lg h-9 sm:px-4 px-3 flex items-center justify-center"
                >{Math.round(100 - item.price.extracted_value / searchedProduct?.price * 100)}% CHEAPER
                </div>
                : null
            }
            <div
              className="right sm:text-xl text-lg h-9 rounded-r-lg border-2 border-text text-text font-bold px-3 sm:px-4 flex items-center justify-center">{item.price.currency}{Math.round(item.price.extracted_value)}</div>
          </div>
          {
            item.in_stock !== undefined ?
              <div className={"text-xs ml-3"}>
                {
                  item.in_stock ?
                    <span>
                  <i className="far fa-check text-primary"></i>
                      {item.in_stock} IN STOCK
                </span>
                    :
                    <span>
                  <i className="far fa-times text-s-tier"></i>
                      {item.in_stock} OUT OF STOCK
                </span>
                }
              </div>
              : null
          }
        </div>
      </div>
    </a>
  )
}

export default function Header() {
  const links: { text: string, path: string }[] = [
    { text: 'Home', path: '/' },
    { text: 'Discover', path: '/discover' },
    { text: 'About Alt Parts', path: '/about' },
  ];
  return (
    <div className="Header fixed top-0 left-0 w-full bg-black sm:bg-background z-10">
      <div className="max-w-screen-lg w-full mx-auto py-3 sm:py-7 px-5 flex items-center justify-between">
        <a href={"/"}>
          <img src={require("./../../assets/img/logo-text.png")} alt="" className={"sm:w-32 sm:h-12 w-21 h-8 object-fill"}/>
        </a>
        <div className="hidden sm:block">
          {
            links.map(({ path, text }) =>
              <a className="decoration-0 ml-8 font-medium text-sm" href="/asd" key={path}>{text}</a>)
          }
        </div>
        <div className="sm:hidden">
          <button><i className="far fa-bars text-text text-2xl"></i></button>
        </div>
      </div>
    </div>
  );
}

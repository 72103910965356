import React from 'react';
import './App.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Discover from './pages/Discover/Discover';
import { ResponsivenessProvider } from './context/ResponsivenessContext';


const router = createBrowserRouter(
  [
    { path: "/*", element: <Home/>, },
    { path: "/search", element: <Home/>, },
    { path: "/about", element: <About/>, },
    { path: "/discover", element: <Discover/>, },
  ]
);

function App() {
  return (
    <ResponsivenessProvider>
      <RouterProvider router={router}/>
    </ResponsivenessProvider>
  );
}

export default App;

import React, { useState } from 'react';


interface Props {
  value: string;
  loading: boolean;
  error: string;
  onSearch: (search: string) => void;
  onForceStopSearch: () => void;
}

export default function SearchBar({ value, loading, error, onSearch, onForceStopSearch }: Props) {
  const [inputValue, setInputValue] = useState(value);

  async function search(event: any) {
    event.preventDefault();
    if (loading) {
      onForceStopSearch();
    } else {
      onSearch(inputValue);
    }
  }


  return (
    <form onSubmit={search} className="SearchBar p-3 rounded-2xl w-full block mt-5 sm:mt-12">
      <div className={"flex sm:flex-row flex-col w-full"}>
        <label className="flex flex-row w-full">
          <div
            className={`bg-[#d8d8d8] py-2 px-3 sm:px-7 rounded-l-xl font-medium text-black ${error ? 'bg-s-tier' : ''}`}>
            alt.parts/
          </div>
          <input
            type={"search"}
            className={`block py-2 pl-3 sm:pl-4 w-full h-10 rounded-none rounded-r-xl placeholder-placeholder ${error ? 'border-2 border-s-tier' : ''}`}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            disabled={loading}
            placeholder={"Paste in your product's URL."}
            onFocus={ev => ev.target.select()}
          />
        </label>
        <button
          className="rounded-xl bg-primary color-background font-bold sm:ml-2 sm:mt-0 mt-2 sm:p-0 p-2 sm:w-40 w-full">
          {!loading ? 'Search' : <i className={"fas fa-square"}></i>}
        </button>
      </div>
    </form>
  );

}

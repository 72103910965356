import React, { useEffect, useState } from 'react';
import SearchBar from '../../components/SearchBar/SearchBar';
import { useSearchParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import LoadingSearch from '../../components/LoadingSearch/LoadingSearch';
import HomeBackground from '../../components/HomeBackground/HomeBackground';
import Result from '../../components/Result/Result';
import useWebsocket from '../../hooks/use-websocket';
import { useOnLocationChange } from '../../hooks/use-on-location-change';


function Home() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchedProduct, setSearchedProduct] = useState<any>(null);
  const [results, setResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [serverError, setServerError] = useState(false);
  const [serverMessage, sendMessage, socket] = useWebsocket({
    wsPath: '/search', serverEventName: 'server-message', clientEventName: 'client-message'
  });

  useOnLocationChange(search, socket);

  useEffect(onServerMessage, [serverMessage]);

  function onServerMessage(): void {
    if (serverMessage?.action === 'error') {
      setServerError(true);
      setLoading(false);
      return;
    }
    setServerError(false);

    if (serverMessage?.action === 'search-complete') {
      setLoading(false);
      console.log(serverMessage);
      if (serverMessage.data?.scrapedProduct) {
        setSearchedProduct(serverMessage.data.scrapedProduct);
      }
      setResults(serverMessage.data.results);
      return;
    }

  }

  function changeSearchParams(searchString: string, perfectFit = false): void {
    setError('');
    setSearchParams({ search_string: searchString, perfect_fit: perfectFit.toString() });
  }

  async function search(searchString: string, perfectFit = false): Promise<void> {
    console.log(searchString);
    setLoading(true);
    sendMessage({
      action: 'start-search',
      params: { search_string: searchString, perfect_fit: perfectFit }
    });
  }

  function stopSearch() {
    // TODO: Actually cancel the search request
    setLoading(false);
  }

  // If there was a comparison to be made, split items between tiers.
  let topItems: any[] = [];
  let restItems: any[] = [];
  if (searchedProduct) {
    topItems = results.filter((item: any) => item?.weightAttributes?.totalScore > 70);
    restItems = results.filter((item: any) => item?.weightAttributes?.totalScore <= 70);
  } else {
    // If there was no searched product to compare, put all items in the top list
    topItems = results;
  }
  const productUrl: string = searchParams.get('search_string') || '';
  const isPerfectFitOn: boolean = searchParams.get('perfect_fit') === 'true';

  return (
    <div className="Home pb-20">
      <HomeBackground show={!results?.length && !error && !loading && !serverError}/>
      <div
        className="flex flex-col row-start-2 justify-center items-center max-w-screen-lg mx-auto w-full mt-16 sm:mt-24 px-5">
        <Header/>
        {
          !loading && !serverError && !results?.length && !error ?
            <div
              className={`hero-text text-base sm:text-xl font-medium mt-12 sm:mt-16 sm:text-center text-center ${!results?.length && !error && !loading ? '' : 'hide'}`}>
              Save on your car parts by finding cheaper alternatives from the entirety of the internet within seconds.
            </div>
            : null
        }

        <SearchBar
          value={productUrl}
          loading={loading}
          error={error}
          onSearch={changeSearchParams}
          onForceStopSearch={stopSearch}
        />

        {!results?.length && !error && !loading && !serverError ?
          <button
            className={`flex items-center bg-background text-base font-bold rounded-xl py-2 px-3 sm:p-3 sm:pr-4 text-text mt-5 sm:mt-12`}>
            <i className="fas fa-play-circle text-2xl sm:text-3xl text-text mr-3"></i> How does it work?
          </button>
          : null
        }

        {error ? <div className="text-white ml-2">{error}</div> : null}

        {loading || serverError ? <LoadingSearch serverMessage={serverMessage}/> : null}

        {searchedProduct && !loading && !serverError
          ?
          <div
            id="searched-product"
            className="w-full mt-5 sm:mt-10 p-5 bg-card-background flex flex-col sm:flex-row rounded-2xl"
          >
            <div className="product-images w-full sm:w-[280px] md:w-[330px] lg:w-[400px]">
              <div className={"main-image"}>
                <img src={searchedProduct.mainImage?.url}
                     alt={"Main product img missing"}
                     className="rounded-lg object-cover  aspect-square"
                />
              </div>
              {
                searchedProduct.images?.slice(0, 4).map((image: any) => (
                  <div className={"secondary-image"}
                       key={image?.url}>
                    <img
                      src={image?.url}
                      alt={"No img"}
                      className="rounded-lg mt-3 first:mt-0 object-cover aspect-square"
                    />
                  </div>
                ))
              }
            </div>

            <div className="details mt-3 sm:ml-10 sm:mt-0 flex-1 flex flex-col justify-between">
              <div>
                <div className="flex flex-row items-center text-sm">
                  <i className="fal fa-link text-primary"></i>
                  <div className="ml-2 capitalize">
                    {searchedProduct?.brand?.name?.toLowerCase()}
                  </div>
                </div>
                <div className={"text-lg sm:text-xl font-bold mt-2 sm:mt-3 text-ellipsis line-clamp-1 sm:line-clamp-2"}>
                  {searchedProduct.name}
                </div>
              </div>
              <div>
                <div className={"text-text-70 text-sm text-ellipsis sm:line-clamp-4 line-clamp-2 mt-2 sm:mt-3"}>
                  {searchedProduct.description}
                </div>
                <div className="price-button flex mt-4 sm:mt-3">
                  <div
                    className="left text-xs font-medium text-black bg-text rounded-l-lg h-9 sm:px-4 px-3 flex items-center justify-center">ORIGINAL
                    PRICE
                  </div>
                  <div
                    className="right sm:text-xl text-lg h-9 rounded-r-lg border-2 border-text text-text font-bold px-3 sm:px-4 flex items-center justify-center">{searchedProduct.currencyRaw}{Math.round(searchedProduct.price)}</div>
                </div>
              </div>
            </div>
          </div>
          :
          null
        }
        <div className="flex flex-col w-full">
          {
            results?.length && !loading
              ? <div>
                <hr className={"mt-5 sm:mt-10 bg-text-10 border-text-10"}/>
                <div className={"flex mt-5 sm:mt-10 flex-row items-center justify-between"}>
                  <div className={"text-base sm:text-xl font-bold flex-1 pr-5 leading-5"}>
                    We found {results.length} {results.length > 1 ? 'alternatives' : 'alternative'}!
                  </div>
                  <div className={"flex flex-row items-center"}>
                    {
                      searchedProduct ?
                        <label className={"bg-card-background rounded-md py-2 px-3 font-bold mr-5"} onClick={() => {
                          window.setTimeout(() => changeSearchParams(productUrl, !isPerfectFitOn), 100)
                        }}>
                          <input type="checkbox" checked={isPerfectFitOn} className={"mr-2"} onChange={() => null}/>
                          Perfect Fit
                        </label>
                        : null
                    }
                    <button
                      className={"border border-primary-30 bg-primary-10 rounded-lg py-2 px-3 text-text text-sm font-medium flex items-center"}>
                      <i className="far fa-filter mr-2"></i>
                      Fine tune
                    </button>
                    <button
                      className={"border border-primary-30 bg-primary-10 rounded-lg py-2 px-3 text-text text-sm font-medium ml-3"}>
                      <i className="far fa-sort mr-2"></i>Sort
                    </button>
                  </div>
                </div>
                {
                  topItems.length > 0 ?
                    <div className={"mt-5"}>
                      <span className={"hidden sm:block"}>Top choices based on your part:</span>
                      {
                        topItems.map((item: any, index: number) =>
                          <Result key={index} item={item} index={index} searchedProduct={searchedProduct}/>
                        )
                      }
                    </div>
                    : null
                }
                {
                  restItems.length > 0 ?
                    <div className={"mt-5"}>
                      {
                        topItems.length > 0 ?
                          <div>
                            <hr className={"sm:my-10 my-5 bg-text-10 border-text-10"}/>
                            More results:
                          </div>
                          : null
                      }
                      {
                        restItems.map((item: any, index: number) =>
                          <Result key={index} item={item} index={index} searchedProduct={searchedProduct}/>
                        )
                      }
                    </div>
                    : null
                }
              </div>
              :
              null
          }
        </div>
      </div>
    </div>
  );
}

export default Home;

import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { ServerMessage } from '../models/server-message.model';

interface SearchWebsocketArgs {
  wsPath: string;
  serverEventName: string;
  clientEventName: string;
}

interface SendMessageArg {
  action: 'start-search';
  params: {
    search_string: string;
    perfect_fit?: boolean;
  }
}

type SearchWebsocketReturnVal = [
  message: any,
  sendMessage: (arg: SendMessageArg) => void,
  ready: boolean
];



export default function useWebsocket({ wsPath, serverEventName, clientEventName }: SearchWebsocketArgs): SearchWebsocketReturnVal {
  const [socket, setSocket] = useState<any>(null);
  const [message, setMessage] = useState<ServerMessage>();
  const [ready, setReady] = useState<boolean>(false);


  // Connect to WebSocket on component mount
  useEffect((): any => {
    console.log('socket init');
    const socket = io(
      'https://alt-parts-node-207d82ef2cbd.herokuapp.com/',
      { path: wsPath }
    );
    setSocket(socket);

    function onConnect() {
      console.log('Websocket connected');
      setReady(true);
    }

    function onDisconnect() {
      console.log('Websocket disconnected');
      setReady(false);
    }

    function onServerEvent(msg: any): void {
      setMessage(msg);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on(serverEventName, onServerEvent);

    // Disconnect on cleanup
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off(serverEventName, onServerEvent);
      socket.disconnect();
    };
  }, []);

  // Function to send message
  const sendMessage = (msg: SendMessageArg) => {
    socket?.emit(clientEventName, msg);
  };

  return [message, sendMessage, ready];
}

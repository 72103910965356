import React, { createContext, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

const ResponsivenessContext = createContext({
  screenSizes: {
    isDesktop: false,
    isLaptop: false,
    isTablet: false,
    isMobile: false,
  }
});

export const ResponsivenessProvider = ({ children }: { children: any }) => {
  const screenSizes = {
    isDesktop: useMediaQuery({ query: '(min-width: 1800px)' }),
    isLaptop: useMediaQuery({ query: '(min-width: 1200px) and (max-width: 1799px)' }),
    isTablet: useMediaQuery({ query: '(min-width: 700px) and (max-width: 1199px)' }),
    isMobile: useMediaQuery({ query: '(max-width: 699px)' })
  }

  return (
    <ResponsivenessContext.Provider value={{ screenSizes }}>
      {children}
    </ResponsivenessContext.Provider>
  );
};

export const useResponsiveness = () => useContext(ResponsivenessContext);

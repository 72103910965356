import { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const useOnLocationChange = (search: (productUrl: string, perfectFit: boolean) => void, socket: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchTriggeredRef = useRef(''); // Use ref to persist across renders without causing re-renders

  useEffect(() => {
    // If the link is domain.com/search_string, redirect to our handled path /search?search_string=search_string
    if (!location.pathname.startsWith('/search')) {
      const searchString = location.pathname.slice(1);
      navigate(`/search?search_string=${searchString}`);
      return;
    }

    const searchString = searchParams.get('search_string');
    if (!searchString || (searchTriggeredRef.current === searchString)) {
      return;
    }
    if (!socket) {
      return;
    }

    searchTriggeredRef.current = searchString; // Mark search as triggered
    search(searchString, searchParams.get('perfect_fit') === 'true');

  }, [location, navigate, searchParams, search, socket]);

};
